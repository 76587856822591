import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enJSON from "./locale/en.json";
import deJSON from "./locale/de.json";

i18n
  .use(initReactI18next)
  // For options read: https://www.i18next.com/overview/configuration-options
  .init({
    compatibilityJSON: "v4",
    // Allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: { main: enJSON },
      de: { main: deJSON },
    },
  });

export default i18n;
